/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class CommerciaPlanService {
  //
  // plans
  //
  static listPlans(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/commercia/plans/list?page=${page}`, params)
  }

  static updatePlan(id, data) {
    return useJwt.put(`/commercia/plan/${id}`, {}, data)
  }

  static addPlan(data) {
    return useJwt.post('/commercia/plan', {}, data)
  }

  static getPlan(id) {
    return useJwt.get(`/commercia/plan/${id}`)
  }

  static deletePlan(id) {
    return useJwt.delete(`/commercia/plan/${id}`)
  }

  //
  // Additionals
  //
  static listAdditionals(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/commercia/plan-additionals/list?page=${page}`, params)
  }

  static updateAdditional(id, data) {
    return useJwt.put(`/commercia/plan-additional/${id}`, {}, data)
  }

  static addAdditional(data) {
    return useJwt.post('/commercia/plan-additional', {}, data)
  }

  static getAdditional(id) {
    return useJwt.get(`/commercia/plan-additional/${id}`)
  }

  static deleteAdditional(id) {
    return useJwt.delete(`/commercia/plan-additional/${id}`)
  }
}
